<template>
  <div class="content flex-box">
    <data-list
      ref="dataList"
      class="flex-grow"
      url="/admin/user"
      entity-name="成员"
      :add-btn="false"
      :query="query"
      hide-show-detail
      hide-edit
      hide-delete
      :custom-url="customListUrl"
      :action="action"
      :columns="columns"
    >
      <template v-slot:query="{form}">
        <a-form-model-item label="">
          <remote-search
            v-model="form.wx_user_id"
            allow-clear
            url="/common/search-contact"
            name-key="keyword"
            :custom-url="customUrl"
            :format-response="formatResponse"
            placeholder="输入姓名/法名搜索"
            style="width: 200px;"
            @input="updateList">
            <template v-slot:title="{option}">
              <open-data :openid="option.id" type="userName" />
            </template>
          </remote-search>
        </a-form-model-item>
      </template>
      <template v-slot:action="{ item }">
        <a class="txt-btn" @click="setFace(item)">设置</a>
      </template>
      <template v-slot:edit>
        <img ref="image" :src="image" alt="" class="face-img" v-if="image" />
      </template>
    </data-list>
    <a-modal
      v-model="visible"
      :width="640"
      title="头像裁剪"
      :body-style="{ padding: 0 }"
      :ok-button-props="{ props: { loading } }"
      @ok="confirm">
      <img ref="image" :src="image" alt="" class="face-img" v-if="image" />
    </a-modal>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

import {base64ToFile, fileToUrl} from "../../common/js/tool";
import {directUploadTencentCloud} from "../../common/js/tencent_cloud";

export default {
  name: "CultureBless",
  data() {
    return {
      columns: [
        {
          title: "姓名/法名",
          dataIndex: "wx_user_id",
          customRender: text => <open-data type="userName" openid={text} />,
        },
        { title: "人脸照片", dataIndex: "face_url", customRender: text => text ? <s-img src={text} style="width:100px;height:100px;" /> : '未上传' },
        { title: "下发状态", dataIndex: "face_validate", customRender: text => <a-tag color={[undefined,'green','red'][text]}>{['未下发', '已下发', '下发失败'][text]}</a-tag> },
      ],
      visible: false,
      user: null,
      image: "",
      query: "",
      cropper: null,
      loading: false,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  methods: {
    customUrl(val, url) {
      if (val) {
        url += `?keyword=${val}&queryType=1`;
        return url;
      }
      return "";
    },
    formatResponse(res) {
      if(res.error == 0) {
        const list = res.data.user ? res.data.user.userid : [];
        return list.map(item => {
          return { id: item };
        })
      }
      return [];
    },
    customListUrl(form) {
      return form.wx_user_id ? `&filter[wx_user_id]=${form.wx_user_id.id}` : "";
    },
    setFace(item) {
      this.user = item;
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
      input.onchange = () => {
        const [file] = input.files;
        const url = fileToUrl(file);
        this.visible = true;
        if (this.cropper) {
          this.cropper.replace(url);
        } else {
          this.image = url;
          this.$nextTick(() => {
            this.cropper = new Cropper(this.$refs.image, {
              aspectRatio: 1,
              viewMode: 3,
              dragMode: "move",
            })
          });
        }
      }
    },
    confirm() {
      if(this.loading) return;
      if (this.cropper) {
        this.loading = true;
        const image = this.cropper.getCroppedCanvas().toDataURL();
        const file = base64ToFile(image, 'face.png');
        directUploadTencentCloud(file).then(data => {
          let path = "https://" + data.Location;
          this.$axios({
            url: `/admin/user/${this.user.id}`,
            method: 'PATCH',
            data: {
              face_url: path,
              face_validate: 0,
            },
          }).then(() => {
            this.updateList();
            this.visible = false;
          }).finally(() => {
            this.loading = false;
          })
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    updateList() {
      this.$refs.dataList.getList();
    },
  },
};
</script>

<style scoped>
  .face-img {
    max-width: 100%;
  }
</style>
